<template>
    <div>
    <slot name="content" />
    <v-text-field
        v-model.trim="inputValue"
        :prepend-inner-icon="icons"
        :append-outer-icon="deleteIcon"
        outlined
        :dark="selectTheme"
        color="primary"
        :label="label"
        :disabled="disabled"
        :error-messages="inputValueErrors"
        @click:append-outer="
            deleteField();  
        "
        @input="
            $v.inputValue.$touch();
            emit();
        "
        @blur="
            $v.inputValue.$touch();
            emit();
        "
    >
    </v-text-field>
    </div>
</template>

<script>
import { url, requiredIf } from "vuelidate/lib/validators";
export default {
    name: "TextInput",

    validations: {
        inputValue: {
            url,
            required: requiredIf(function () {
                return this.requiredInput;
            })
        },
    },

    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
        currentValue: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            required: true,
            type: String,
        },
        icons: {
            required: false,
            type: String,
            default: ""
        },
        deleteIcon: {
            required: false,
            type: String,
            default: ""
        },
        selectTheme: {
            required: false,
            type: Boolean,
            default: false
        },
        requiredInput: {
            required: false,
            type: Boolean,
            default: true
        },
        validUrl: {
            required: false,
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            inputValue: "",
        };
    },

    computed: {
        inputValueErrors() {
            const errors = [];
            if (!this.$v.inputValue.$dirty) return errors;
            if(this.validUrl) {
                !this.$v.inputValue.url &&
                errors.push(this.$i18n.t(`ErrorMessages.validUrl`));
            }
            return errors;
        },
    },

    methods: {
        emit() {
            this.$emit("update:input-value", this.inputValue);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
        deleteField() {
            this.$emit("delete-field");
        }
    },

    watch: {
        currentValue(newval) {
            if (newval) this.inputValue = newval
        },
    },

    created() {
        if (this.currentValue) {
            this.inputValue = this.currentValue;
        }
    },
};
</script>

<style lang="scss" scoped></style>

