<template>
  <v-container fluid>
    <v-row class="my-10">
        <v-col
         cols="12"
         md="5"
        >
            <div class="d-flex align-center">
                <v-icon
                 normal
                 left
                 color="purple"
                >
                  fa-thin fa-address-card
                </v-icon>
                <h2 class="main-heading">
                    Public Profile
                </h2>
            </div>
            <base-card card-height="600" style="background: #eeeeeecf;">
                <template #content>
                    <div class="d-flex flex-column align-center">
                        <v-img
                          :src="isLogoExists"
                          contain
                          width="200"
                          height="200"
                          alt="use-profile-image"
                          style="border: 1px solid #33CC33"
                          class="rounded-circle"
                        >
                        </v-img>
                        <v-divider
                          inset
                          class="my-7"
                        >
                        </v-divider>
                          <v-row>
                            <v-col>
                             <div class="d-flex flex-column">
                                <div class="d-flex justify-start align-center">
                                    <v-icon 
                                    normal
                                    left
                                    color="primary"
                                    >
                                    fa-solid fa-user
                                    </v-icon>
                                    <v-card-title>Name:</v-card-title>
                                    <span>{{ userData.fullName }}</span>
                                </div>

                                <div class="d-flex align-center">
                                    <v-icon
                                    normal
                                    left
                                    color="primary"
                                    >
                                    fa-solid fa-location-dot
                                    </v-icon>
                                    <v-card-title>Address:</v-card-title>
                                    <span>{{ userData.address ? userData.address : 'Not Provided' }}</span>
                                </div>

                                <div class="d-flex align-center">
                                    <v-icon
                                    nomral
                                    color="primary"
                                    >
                                        fa-solid fa-phone
                                    </v-icon>
                                    <v-card-title>Contact:</v-card-title>
                                    <span>{{ userData.contact_email.length == 0 ? 'Not Provided' : userData.contact_email[0] }}</span>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <a
                           class="edit-card-position primary--text"
                          >
                            <span @click="editProfile()">Edit Public Profile</span>
                          </a>
                    </div>
                </template>
            </base-card>
        </v-col>
        <v-col
         cols="12"
         md="7"
        >
          <div class="d-flex align-center">
            <v-icon
              normal
              left
              color="purple"
            >
              fa-thin fa-chart-pie
            </v-icon>
            <h2 class="main-heading">
                Maturity Tool
            </h2>
          </div>
          <base-card
            card-height="600"
          >
            <template #content>
                <!-- <v-img
                  contain
                  height="300px" 
                  src="../../assets/pie-chart.svg"
                  alt="forum-background-image"
                  class="mt-15"
                >
                </v-img> -->
                <iframe
                  v-show="!isLoading"
                  ref="questionaireFrameChart"
                  :src="maturityURL"
                  width="100%"
                  height="540px"
                  style="border: none !important; margin-top: 0.5rem;"
                  class="maturity_iframe"
                />
                <v-skeleton-loader
                  v-show="isLoading"
                  type="card-heading, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-two-line"
                >
                </v-skeleton-loader>
            </template>
          </base-card>
        </v-col>
    </v-row>
    <v-row class="mt-15">
            <v-col>
                <div class="d-flex align-center">
                  <v-icon 
                    normal
                    left 
                    color="purple"
                  >
                   fa-thin fa-gear
                  </v-icon>
                  <h2 class="main-heading">
                    Service Box
                  </h2>
                </div>
                <base-card>
                    <template #content>
                    <v-row class="mb-10">
                      <v-col cols="12">
                      <div
                         v-if="getUserDataStatus_Idle || getUserDataStatus_Pending"
                        >
                         <v-col
                            v-for="skeleton in 2"
                            :key="skeleton"
                         >
                            <v-skeleton-loader
                                type="card-heading, list-item-three-line, list-item-three-line, actions"
                            >
                            </v-skeleton-loader>
                         </v-col>
                      </div>
                      <div
                       v-else-if="userData.service_box.length <= 0"
                       class="d-flex flex-column align-center"
                      >
                        <h1 class="mt-10 main-heading">There are no services provided</h1>
                        <p class="font-weight-light">You may come back later</p>
                        <v-img
                         src="../../assets/noData.svg"
                         width="300px"
                         height="250px"
                         class="mt-15"
                         contain
                        ></v-img>
                      </div>
                      <div 
                       v-else
                      >
                       <v-card
                         v-for="(service, index) in userData.service_box"
                         :key="index"
                         class="mb-5 forum-card d-flex"
                       >
                        <v-row>
                        <v-col cols="12" md="3" sm="12">
                          <iframe
                           v-if="service.video"
                           width="100%"
                           height="250px"
                           loading="eager" 
                           :src="formatedVideo(service.video)"
                           frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                          <v-img
                           v-else 
                           src="../../assets/noVideo.jpg"
                           height="250px"
                           contain
                          ></v-img>
                        </v-col>
                        <v-col class="ma-3">
                            <div class="d-flex align-center">
                              <v-icon x-small left class="primary--text">fa fa-circle</v-icon>
                              <h3 class="main-heading">
                                {{ service.title }}
                              </h3>
                            </div>
                            <v-chip-group
                             v-if="service.serviceGroups"
                              center-active
                              show-arrows
                              column
                            >
                              <v-chip
                                v-for="(group, index) in service.serviceGroups"
                                :key="index"
                                class="mb-5"
                                color="purple secondary--text"
                                min-width="30"
                                max-width="100"
                              >
                                <v-icon 
                                 x-small
                                 left
                                >
                                  fa-solid fa-tag
                                </v-icon>
                                {{ group.name }}
                              </v-chip>
                            </v-chip-group>
                            <p v-else class="mt-5">Services: <span class="font-weight-light">Not Provided</span></p>
                            <p>Content Type: <span class="font-weight-light">{{ service.content_type || 'Not Provided'}}</span></p>
                        </v-col>
                         <a
                            class="edit-card-position primary--text"
                            >
                                <span @click="serviceDetails(service.id)">Details</span>
                            </a>
                    </v-row>
                 </v-card>
                </div>
              </v-col>
              </v-row>
               <a
                  v-if="getUserDataStatus_Success"
                  class="edit-card-position primary--text"
                >
                    <span @click="servicesPage()">Go to Service Box</span>
                </a>
             </template>
         </base-card>
     </v-col>
    </v-row>
        <v-row class="my-10">
            <v-col>
                <base-card 
                 card-height="250"
                 class="forum-background"
                >
                <template #content>
                  <v-row>
                  <v-col 
                    cols="12"
                  >
                   <div class="d-flex flex-column align-center mt-10">
                    <h3 class="forum-banner-heading">Visit our Forums</h3>
                    <a :href="forumURL" style="text-decoration: none;">
                     <v-btn
                      outlined
                      width="200px"
                      style="border: none;"
                      class="purple secondary--text mt-10 pa-7 forum-banner-button-hover"
                    >
                      <v-icon left small>
                        fa fa-newspaper
                      </v-icon>
                      Go to Forum
                    </v-btn>
                    </a>
                   </div>
                  </v-col>
              </v-row>
             </template>
         </base-card>
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONFIG }   from "@/common/config";
import { apiStatus } from '../../api/constants/apiStatus'
import { apiStatusComputed } from '../../api/helpers/computedApiStatus';
import { withAsync } from '@/helpers/withAsync';
import { getUserData } from '@/api/homePageApi.js';
import { getMaturityUrl } from "@/api/maturityApi.js"
import BaseCard from "../../components/base/BaseCard.vue";
import SocialMediaInput from "../../components/Inputs/SocialMediaInput.vue";
import SelectInput from '../../components/Inputs/SelectInput.vue';

import { mapGetters } from "vuex";

export default {
    name: "DihHomePage",
    components: {
        BaseCard,
        SocialMediaInput,
        SelectInput,
    },
    data() {
        return {
            getUserDataStatus: apiStatus.Idle,
            getQuestionaryIdStatus: apiStatus.Idle,
            getIframeStatus: apiStatus.Idle,
            forumURL: CONFIG.forum_url,
            userData: {
              fullName: '',
              id: null,
              logo: null,
              picture: null,
              contact_email: [],
            },
            maturityURL: '',
            isLoading: true,
        };
    },
    computed: {
      ...apiStatusComputed(["getUserDataStatus", "getQuestionaryIdStatus", "getIframeStatus"]),
      ...mapGetters({
        userID: "auth/getUserId",
        userToken: "auth/getToken"
      }),
      isLogoExists() {
        return this.userData.logo == null ? require ('../../assets/Image_not_available.png') : this.userData.logo.path
      },
    },
    methods: {
      formatUrl(url) {
           const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/; 
           const mather = url.match(regExp);

           if (mather && mather[2].length == 11) {
               return mather[2];
           } else {
               return null;
           }
      },
      formatedVideo(video) {
        if(video != null) {
          const videoID = this.formatUrl(video);
          return `https://www.youtube.com/embed/${videoID}`
        }
      },
      serviceDetails(id) {
        this.$router.push({name: 'ToolBox Card Details', params: {id: id} });
      },
      servicesPage() {
        this.$router.push({name: 'Toolbox'});
      },
      async fetchUserData() {
        this.getUserDataStatus = apiStatus.Pending;

        const { response, error } = await withAsync(getUserData);

        if (error) {
          this.getUserDataStatus = apiStatus.Error
          return;
        }

        setTimeout(() => {
          this.getUserDataStatus = apiStatus.Success;
        }, 150);

        this.getUserDataStatus = apiStatus.Success;
        this.userData = {
          fullName: response.data.data.public_profile.contact_fullname,
          id: response.data.data.public_profile.id,
          logo: response.data.data.public_profile.logo,
          picture: response.data.data.public_profile.picture,
          contact_email: response.data.data.public_profile.contact_email,
          service_box: response.data.data.service_box,
        }
        this.networkingOportunities = response.data.data.networking_opportunities.slice(0,3);
      },
      async getQuestionaryId() {
        await this.fetchMaturityIframe();
        this.getQuestionaryIdStatus = apiStatus.Pending;

        const { response, error } = await withAsync(getMaturityUrl);

        if (error) {
          this.getQuestionaryIdStatus = apiStatus.Error;
          return;
        }
        this.getQuestionaryIdStatus = apiStatus.Success;
        // this.maturityURL = response.data.data.url.concat(`latest-questionnaire/${this.userID}`)
        this.maturityURL = response.data.data.url.concat(`questionnaire-radar-plot/${this.userID}`);

        setTimeout(() => {
          this.sendPostMessage()
        }, 900);
      },
      async fetchMaturityIframe () {
        this.getIframeStatus = apiStatus.Pending;

        const { response, error } = await withAsync(getMaturityUrl);

        if (error) {
          this.getIframeStatus = apiStatus.Error;
          return;
        }

        this.getIframeStatus = apiStatus.Success;
        setTimeout(() => {
          this.sendPostMessage(response.data.data.url);
        }, 900);

      },
      sendPostMessage(res) {
        // const token = localStorage.getItem("agrobofood_token");
        // const userId = localStorage.getItem("agrobofood_user_id");

        let maturityFrame = this.$refs.questionaireFrameChart;

        maturityFrame.contentWindow.postMessage({
          user_token: this.userToken,
          user_id: this.userID,
        }, res ? res : this.maturityURL);

        if (!res || typeof res == 'undefined') {
          setTimeout(() => {
            maturityFrame.src = this.maturityURL;
            setTimeout(() => {
              this.isLoading = false;
            }, 450);
          }, 800)
        }

      },
      editProfile() {
        this.$router.push("/public-profile");
      },
      showOportunity(id) {
        this.$router.push({ name: "NetworkingOpportunity" , params: {id: id} });
      },
      allOportunities() {
        this.$router.push({ name: 'NetworkingOpportunities' });
      },
      createOportunity() {
        this.$router.push({ name: "NetworkingOpportunitiesCreate" });
      }
    },
    created() {
      this.getQuestionaryId();
      this.fetchUserData();
    }
};
</script>

<style scoped>
#pie-chart-placeholdwer {
  width: 90% !important;
  object-fit: contain !important;
}
.forum-banner-heading {
  font-family: "Yanone Kaffeesatz", sans-serif;
  color: #3B4C55;
}
.divider-container {
    width: 25px;
    margin-left: 1rem;
    margin-top: -0.7rem;
    border-top: 2px solid #C35272;
}
.divider-container-large {
    width: 100px;
    border-top: 2px solid #C35272;
}
.edit-card-position {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1.5rem;
}
.edit-card-position-top {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.5rem;
}
.overflow-description {
  width: 95%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.forum-card:hover {
    background-image: linear-gradient(100deg,#fdfcfb 0%,#eee 100%) !important;
    /* background: #E8E6D3cf !important; */
    transition-duration: 0.3s ease;
}
.text-background::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: lightgoldenrodyellow;
    transform: rotate(1deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 30%;
    z-index: -1;
}
.text-background {
    position: relative;
    margin-left: 0.5rem;
    z-index: 1;
}
a:hover {
    color: #C35272 !important;
}
/* .newsletter-button-hover:hover {
  background: #ffb26b !important;
  transition: 0.3s ease-in;
  transform: scale(1.03);
} */
.forum-banner-button-hover:hover {
  background: #33CC33 !important;
  transition: 0.3s ease-in;
  transform: scale(1.03);
}
.forum-background::before {
  content: '';
  position: absolute;
  width: 100%;
  opacity: 0.2;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: url('../../assets/polygon-pattern.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.forum-background {
  background-image: linear-gradient(to bottom,#fdfcfb 0%,#eeeeeecf 100%) !important;
  z-index: 1;
}
</style>