import { api } from "./api";


const URLS = {
    homepage: "/homepage",
};


export const getUserData = () => {
    return api.get(`${URLS.homepage}`)
};