<template>
    <v-container fluid>
        <v-row class="my-10">
            <v-col 
              cols="12"
              md="4"
            >
                <div class="d-flex align-center">
                  <v-icon 
                    normal
                    left 
                    color="purple"
                  >
                     fa-thin fa-address-card
                  </v-icon>
                  <h2 class="main-heading">
                     Public Profile
                  </h2>
                </div>
                <base-card card-height="570" style="background: #eeeeeecf;">
                    <template #content>
                         <div class="d-flex flex-column align-center">
                          <v-img
                           :src="isLogoExists"
                           contain
                           width="200"
                           height="200"
                           alt="user-profile-image"
                           style="border: 1px solid #33CC33"
                           class="rounded-circle"
                          >
                          </v-img>
                          <v-divider 
                            inset
                            class="my-7"
                          >
                          </v-divider>
                          <v-row>
                            <v-col>
                             <div class="d-flex flex-column">
                                <div class="d-flex justify-start align-center">
                                    <v-icon 
                                    normal
                                    left
                                    color="primary"
                                    >
                                    fa-solid fa-user
                                    </v-icon>
                                    <v-card-title>Name:</v-card-title>
                                    <span>{{ userData.fullName }}</span>
                                </div>

                                <div class="d-flex align-center">
                                    <v-icon
                                    normal
                                    left
                                    color="primary"
                                    >
                                    fa-solid fa-location-dot
                                    </v-icon>
                                    <v-card-title>Address:</v-card-title>
                                    <span>{{ userData.address ? userData.address : 'Not Provided' }}</span>
                                </div>

                                <div class="d-flex align-center flex-wrap">
                                    <v-icon
                                    normal
                                    color="primary"
                                    >
                                        fa-solid fa-phone
                                    </v-icon>
                                    <v-card-title>Contact:</v-card-title>
                                    <span>{{ userData.contact_email.length == 0 ? 'Not Provided' : userData.contact_email[0] }}</span>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <a
                           class="edit-card-position primary--text"
                          >
                            <span @click="editProfile()">Edit Profile</span>
                          </a>
                        </div>
                    </template>
                </base-card>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
                <div class="d-flex align-center">
                  <v-icon 
                    normal
                    left
                    color="purple"
                  >
                    fa-thin fa-lightbulb
                  </v-icon>
                  <h2 class="main-heading">
                    Solutions
                  </h2>
                </div>
                <base-card card-height="570">
                    <template 
                     v-if="getUserDataStatus_Idle || getUserDataStatus_Pending"
                     #content
                    >
                     <v-skeleton-loader
                      type="divider, image, list-item-three-line, list-item-three-line, card-heading, actions"
                    >
                    </v-skeleton-loader>
                    </template>
                    <template
                     v-else-if="limitUserSolutions.length == 0"
                     #content
                    >
                     <div class="d-flex align-center flex-column mt-15">
                       <h3 class="main-heading error--text">
                        No Solutions Provided
                       </h3>
                       <p class="mb-10">
                        You can create a new one 
                        <a 
                          class="primary--text font-weight-bold"
                          @click="createSolution()"
                        >
                        here
                        </a>
                       </p>
                       <v-img
                        src="../../assets/noData.svg"
                        width="200"
                        height="250"
                        contain
                       >
                      </v-img>
                     </div>
                    </template>
                     <template 
                      v-else
                      #content
                     >
                        <v-img
                          :src="bannerSolutionImage"
                          min-width="200"
                          max-width="100%"
                          height="200"
                          alt="user-profile-image"
                          class="rounded-lg"
                        >
                        </v-img>
                        <div 
                          v-for="(solution, index) in limitUserSolutions"
                          :key="index"
                        >
                          <v-divider
                            class="my-5"
                          >
                          </v-divider>
                          <v-row>
                            <v-col>
                            <div>
                                <div>
                                    <v-card-title>
                                        {{ solution.name }}
                                    </v-card-title>
                                    <div class="divider-container" />
                                    <v-card-subtitle class="overflow-description">
                                      {{ solution.description }}
                                    </v-card-subtitle>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <a
                           class="edit-card-position primary--text"
                          >
                            <span @click="editSolution()">View All Solutions</span>
                          </a>
                        </div>
                    </template>
                </base-card>
            </v-col>
        </v-row>
        <v-row class="my-10">
            <v-col>
                <base-card 
                 card-height="250"
                 class="forum-background"
                >
                <template #content>
                  <v-row>
                  <v-col 
                    cols="12"
                  >
                   <div class="d-flex flex-column align-center mt-10">
                    <h3 class="forum-banner-heading">Visit our Forums</h3>
                    <a :href="forumURL" style="text-decoration: none;">
                     <v-btn
                      outlined
                      width="200px"
                      style="border: none;"
                      class="purple secondary--text mt-10 pa-7 forum-banner-button-hover"
                     >
                      <v-icon left small>
                        fa fa-newspaper
                      </v-icon>
                      Go to Forum
                     </v-btn>
                    </a>
                   </div>
                  </v-col>
              </v-row>
             </template>
         </base-card>
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONFIG }   from "@/common/config";
import { apiStatus } from '../../api/constants/apiStatus'
import { apiStatusComputed } from '../../api/helpers/computedApiStatus';
import { withAsync } from '@/helpers/withAsync';
import { getUserData } from '@/api/homePageApi.js';
import BaseCard from '../../components/base/BaseCard.vue';
import SocialMediaInput from '../../components/Inputs/SocialMediaInput.vue';

export default {
    name: "SmeHomePage",
    components: {
        BaseCard,
        SocialMediaInput
    },
    data() {
        return {
          getUserDataStatus: apiStatus.Idle,
          forumURL: CONFIG.forum_url,
          userData: {
            fullName: '',
            id: null,
            logo: null,
            picture: null,
            contact_email: [],
          },
          limitUserSolutions: [],
          networkingOportunities: [],
          bannerSolutionImage: '',
        }
    },
    computed: {
      ...apiStatusComputed("getUserDataStatus"),
      isLogoExists() {
        return this.userData.logo == null ? require ('../../assets/Image_not_available.png') : this.userData.logo.path
      }
    },
    methods: {
      async fetchUserData() {
        this.getUserDataStatus = apiStatus.Pending;

        const { response, error } = await withAsync(getUserData);

        if (error) {
          this.getUserDataStatus = apiStatus.Error
          return;
        }

        setTimeout(() => {
          this.getUserDataStatus = apiStatus.Success;
        }, 150);

        this.userData = {
          fullName: response.data.data.public_profile.contact_fullname,
          id: response.data.data.public_profile.id,
          logo: response.data.data.public_profile.logo,
          picture: response.data.data.public_profile.picture,
          contact_email: response.data.data.public_profile.contact_email,
        }
        this.bannerSolutionImage = response.data.data.solutions[0]?.image?.path;
        this.limitUserSolutions = response.data.data.solutions.slice(0,2);
        this.networkingOportunities = response.data.data.networking_opportunities.slice(0,3);
      },
      editSolution() {
        this.$router.push({ name: "My Solutions"});
      },
      editProfile() {
        this.$router.push("/public-profile");
      },
      showOportunity(id) {
        this.$router.push({ name: "NetworkingOpportunity" , params: {id: id} });
      },
      allOportunities() {
        this.$router.push({ name: 'NetworkingOpportunities' });
      },
      createOportunity() {
        this.$router.push({ name: "NetworkingOpportunitiesCreate" });
      },
      createSolution() {
        this.$router.push({ name: "Create - Edit Solution" });
      }
    },
    created() {
      this.fetchUserData();
    }
}
</script>

<style scoped>
.forum-banner-heading {
  font-family: "Yanone Kaffeesatz", sans-serif;
  color: #3B4C55;
}
.divider-container {
    width: 25px;
    margin-left: 1rem;
    margin-top: -0.7rem;
    border-top: 2px solid #C35272;
}
.divider-container-large {
    width: 100px;
    border-top: 2px solid #C35272;
}
.edit-card-position {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem;
}
.overflow-description {
  width: 95%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.forum-card:hover {
    background-image: linear-gradient(100deg, #fdfcfb 0%, #eee 100%) !important;
    /* background: #E8E6D3cf !important; */
    transition-duration: 0.3s ease;
}
a:hover {
    color: #C35272 !important;
}
.text-background::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: lightgoldenrodyellow;
    transform: rotate(1deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 30%;
    z-index: -1;
}
.text-background {
    position: relative;
    margin-left: 0.5rem;
    z-index: 1;
}
.forum-banner-button-hover:hover {
  background: #33CC33 !important;
  transition: 0.3s ease-in;
  transform: scale(1.03);
}
.forum-background::before {
  content: '';
  position: absolute;
  width: 100%;
  opacity: 0.2;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: url('../../assets/polygon-pattern.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.forum-background {
  background-image: linear-gradient(to bottom,#fdfcfb 0%,#eeeeeecf 100%) !important;
  z-index: 1;
}
.scale:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transition-duration: .3s;
}
</style>