<template>
    <v-container>
        <dih-home-page v-if="determineUser == 1"></dih-home-page>
        <sme-home-page v-if="determineUser == 2"></sme-home-page>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DihHomePage from "./DIH/DihHomePage.vue";
import SmeHomePage from "./SME/SmeHomePage.vue";

export default {
    name: "HomePage",
    components: {
        DihHomePage,
        SmeHomePage,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            determineUser: "auth/getUserRole",
        }),
    },
};
</script>

<style scoped>

</style>
